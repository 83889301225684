import { SiteContext } from "@organic-return/foundation-gatsby-plugin-agility/src/components/common/SiteContext"
import { IListingSummary } from "@organic-return/foundation-react/src/lib/Listing"
import { Sort } from "@organic-return/foundation-react/src/components/Search/Sort"
import React from "react"
import { getModule } from "../components/agility-pageModules"
import {
  Check,
  Choose,
  Text,
  DateRange,
} from "@organic-return/foundation-react/src/components/Search/Filter"
import { getPageTemplate } from "../components/agility-pageTemplates"
import { IAgent } from "@organic-return/foundation-react/src/lib/Contact"
import { FooterLabeledColumns } from "@organic-return/foundation-react/src/components/Footer/FooterLabeledColumns"
import { EListingVitalsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingVitals"
import { EListingDetailsLayout } from "@organic-return/foundation-react/src/components/Listing/ListingDetails"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import { ESellFormLayout } from "@organic-return/foundation-react/src/components/Forms/SellForm"
import { ISearchContext } from "@organic-return/foundation-react/src/components/Search/SearchContext"
import { EContactCardLayout, EOfficeInfoPosition } from "@organic-return/foundation-react/src/components/Contact"
import { ListingLayoutSingleColumn } from "@organic-return/foundation-react/src/components/Listing"
import { EMapTheme } from "@organic-return/foundation-react/src/lib/util"

export const SiteContextProvider: React.FC = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteContextProviderQuery {
      config: agilitySiteConfiguration {
        customFields {
          rentalSearchPath
          footerCopyright
          thirdColumnContent
        }
        columnsTitles         
      }
      rentalFeatures: allRental {
        features: distinct(field: features)
      }
      allListing(filter:{status:{ne:"Closed"}}) {
        distinct(field: majorArea)
      }
      allRental {
        distinct(field: address___city)
      }
    }
  `)

  const majorAreaOptions = data.allListing?.distinct?.map((majorArea: string) => ({ label: majorArea, value: majorArea }))
  const rentalCityOptions = data.allRental?.distinct?.map((city: string) => ({ label: city, value: city }))
  const unwantedFeatureOptions = ["Ski In", "Ski Out", "Ski In / Ski Out", "Walk To Ski Area", "Shuttle Service To Ski Area", "Drive To Ski Area"]
  const rentalFeaturesOptions = data.rentalFeatures
    ?.features?.filter((feature: string) => !unwantedFeatureOptions.includes(feature))
    ?.map((feature: string) => ({ label: feature, value: feature }))

  const selectStyles = {
    control: (base: object) => ({
      ...base,
      borderColor: "transparent",
      background: "transparent",
      borderBottomColor: "var(--color-composite-text)",
      borderRadius: 0,
    }),
    option: (base: object) => ({
      ...base,
      color: "var(--color-body-text)",
    }),
    singleValue: (base: any) => ({
      ...base,
      color: "var(--color-composite-text)",
      textTransform: "uppercase",
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      paddingRight: "0px",
      color: "var(--color-composite-text)",
    }),
    indicatorSeparator: (base: object) => ({
      ...base,
      display: "none",
    }),
    placeholder: (base: object) => ({
      ...base,
      color: "var(--color-composite-text)",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
    }),
    valueContainer: (base: object) => ({
      ...base,
      paddingLeft: 0,
      textTransform: "uppercase",
    }),
  }
  const priceFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        label="Min Price"
        placeholder="Min Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.price || 0) >= parseInt(vstr) || false
        }}
        param="minPrice"
        resolver={arg => arg.price}
        reactSelectStyles={selectStyles}
        options={[
          { label: "> $200k", value: "200000" },
          { label: "> $400k", value: "400000" },
          { label: "> $600k", value: "600000" },
          { label: "> $800k", value: "800000" },
          { label: "> $1M", value: "1000000" },
          { label: "> $3M", value: "3000000" },
          { label: "> $5M", value: "5000000" },
          { label: "> $7M", value: "7000000" },
          { label: "> $10M", value: "10000000" },
          { label: "> $15M", value: "15000000" },
        ]}
      />
      <Choose
        context={context}
        label="Max Price"
        placeholder="Max Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.price || 0) <= parseInt(vstr) || false
        }}
        param="maxPrice"
        resolver={arg => arg.price}
        reactSelectStyles={selectStyles}
        options={[
          { label: "< $200k", value: "200000" },
          { label: "< $400k", value: "400000" },
          { label: "< $600k", value: "600000" },
          { label: "< $800k", value: "800000" },
          { label: "< $1M", value: "1000000" },
          { label: "< $3M", value: "3000000" },
          { label: "< $5M", value: "5000000" },
          { label: "< $7M", value: "7000000" },
          { label: "< $10M", value: "10000000" },
          { label: "< $15M", value: "15000000" },
        ]}
      />
    </>
  )
  const rentalPriceFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        label="Min Price"
        placeholder="Min Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.priceLow || 0) >= parseInt(vstr) || false
        }}
        param="minPrice"
        resolver={arg => arg.priceLow}
        reactSelectStyles={selectStyles}
        options={[
          { label: "> $1k", value: "1000" },
          { label: "> $2k", value: "2000" },
          { label: "> $3k", value: "3000" },
          { label: "> $5k", value: "5000" },
          { label: "> $7k", value: "7000" },
          { label: "> $10k", value: "10000" },
          { label: "> $15k", value: "15000" },
          { label: "> $20k", value: "20000" },
          { label: "> $30k", value: "30000" },
          { label: "> $40k", value: "40000" },
        ]}
      />
      <Choose
        context={context}
        label="Max Price"
        placeholder="Max Price"
        customFilter={(arg, value) => {
          let argl = arg as IListingSummary
          let vstr = value as string
          return (argl.priceLow || 0) <= parseInt(vstr) || false
        }}
        param="maxPrice"
        resolver={arg => arg.priceLow}
        reactSelectStyles={selectStyles}
        options={[
          { label: "< $1k", value: "1000" },
          { label: "< $2k", value: "2000" },
          { label: "< $3k", value: "3000" },
          { label: "< $5k", value: "5000" },
          { label: "< $7k", value: "7000" },
          { label: "< $10k", value: "10000" },
          { label: "< $15k", value: "15000" },
          { label: "< $20k", value: "20000" },
          { label: "< $30k", value: "30000" },
          { label: "< $40k", value: "40000" },
        ]}
      />
    </>
  )
  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingStandardFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="area2"
        resolver={arg => arg.minorArea}
        match="any"
        reactSelectStyles={selectStyles}
      />
      <Choose
        context={context}
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="count"
        match="any"
        reactSelectStyles={selectStyles}
      />
      {priceFilters}
      <Choose
        context={context}
        placeholder="Features"
        label="Features"
        param="features"
        resolver={arg => arg.features}
        sort="count"
        match="all"
        reactSelectStyles={selectStyles}
      />
    </>
  )
  /**
   * Standard filters for global Real Estate listing search.
   */
  const listingEntryFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Location"
        label="Location"
        param="area2"
        resolver={arg => arg.minorArea}
        match="one"
        reactSelectStyles={selectStyles}
        options={[
          {
            value: "Aquinnah",
            label: "Aquinnah",
          },
          {
            value: "Chilmark",
            label: "Chilmark",
          },
          {
            value: "Edgartown",
            label: "Edgartown",
          },
          {
            value: "Oak Bluffs",
            label: "Oak Bluffs",
          },
          {
            value: "Vineyard Haven",
            label: "Vineyard Haven",
          },
          {
            value: "West Tisbury",
            label: "West Tisbury",
          },
        ]}
      />
      <Choose
        context={context}
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="count"
        match="one"
        reactSelectStyles={selectStyles}
        options={[
          {
            value: "Single Family",
            label: "Single Family",
          },
          {
            value: "Land",
            label: "Land",
          },
          {
            value: "Single Family Detached",
            label: "Single Family Detached",
          },
          {
            value: "Condo/Townhouse",
            label: "Condo/Townhouse",
          },
          {
            value: "Commercial",
            label: "Commercial",
          },
          {
            value: "Multi-Family",
            label: "Multi-Family",
          },
          {
            value: "Land -Single Family Acreage",
            label: "Land -Single Family Acreage",
          },
        ]}
      />
      {priceFilters}
    </>
  )
  /**
   * Standard filters for community page Real Estate listing search.
   */
  const communityStandardFilters = (context: ISearchContext) => (
    <>
      <Choose
        context={context}
        placeholder="Type"
        label="Type"
        param="type"
        resolver={arg => arg.subType}
        sort="count"
        reactSelectStyles={selectStyles}
        match="any"
      />
      {priceFilters}
      <Choose
        context={context}
        placeholder="Features"
        label="Features"
        param="features"
        resolver={arg => arg.features}
        sort="count"
        match="all"
        reactSelectStyles={selectStyles}
      />
    </>
  )
  /**
   * Sorts to be used in all Listing (real estate) searches
   */
  const listingSort = (context: ISearchContext) => (
    <Sort
      context={context}
      className="text-center py-1"
      label="Sort by: "
      sorts={[
        {
          label: "Price: $$$ to $",
          key: "priceDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.price || 0) - (l0.price || 0)
          },
        },
        {
          label: "Price: $ to $$$",
          key: "priceASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.price || 0) - (l1.price || 0)
          },
        },
        {
          label: "Bedrooms: High to Low",
          key: "bedsDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.beds || 0) - (l0.beds || 0)
          },
        },
        {
          label: "Bedrooms: Low to High",
          key: "bedsASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.beds || 0) - (l1.beds || 0)
          },
        },
        {
          label: "Sq Ft: High to Low",
          key: "sqftDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.sqft || 0) - (l0.sqft || 0)
          },
        },
        {
          label: "Sq Ft: Low to High",
          key: "sqftASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.sqft || 0) - (l1.sqft || 0)
          },
        },
        {
          label: "Lot Size: High to Low",
          key: "lotDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.lotacres || 0) - (l0.lotacres || 0)
          },
        },
        {
          label: "Lot Size: Low to High",
          key: "lotASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.lotacres || 0) - (l1.lotacres || 0)
          },
        },
        {
          label: "Last Updated",
          key: "statusTSDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.statusTS || "").localeCompare(l0.statusTS || "")
          },
        },
      ]}
    />
  )
  /**
   * Middle filter for real estate listing search
   */
  const listingMiddleFilters = (context: ISearchContext) => (
    <>
      <Text
        context={context}
        placeholder="MLS#, City, Zip, Area"
        param="text"
        label="MLS#, City, Zip, Area"
        inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
            (argl.address.text &&
              argl.address.text.toLowerCase().includes(match)) ||
            (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
            (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
      <Check
        context={context}
        label="Our Properties Only"
        inputClassName=" bg-transparent"
        param="ours"
        className="text-composite-text"
        resolver={(arg: any) => {
          let argl = arg as IListingSummary
          return argl.exclusive || false
        }}
      />
    </>
  )
  /**
   * Standard filters for global Rental entry listing search.
   */
  const rentalEntryFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={rentalCityOptions}
        />
        <Choose
          context={context}
          label="Max Price"
          placeholder="Max Price"
          customFilter={(arg, value) => {
            let argl = arg as IListingSummary
            let vstr = value as string
            return (argl.priceLow || 0) <= parseInt(vstr) || false
          }}
          param="maxPrice"
          resolver={arg => arg.priceLow}
          reactSelectStyles={selectStyles}
          options={[
            { label: "< $1k", value: "1000" },
            { label: "< $2k", value: "2000" },
            { label: "< $3k", value: "3000" },
            { label: "< $5k", value: "5000" },
            { label: "< $7k", value: "7000" },
            { label: "< $10k", value: "10000" },
            { label: "< $15k", value: "15000" },
            { label: "< $20k", value: "20000" },
            { label: "< $30k", value: "30000" },
            { label: "< $40k", value: "40000" },
          ]}
        />
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
      </>
    )
  }

  const rentalSearchCheckBoxes = function (context: ISearchContext) {
    return (
      <div className="row-span-2 col-span-2">
        <label className="col-span-2 text-uppercase" >SKI ACCESS</label>
        <div className="text-[0.8em] mt-5 col-span-2 row-span-2 grid grid-cols-2 grid-rows-3 grid-flow-col gap-2">
          <Check
            context={context}
            label="Ski In"
            inputClassName="bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
            param="skiIn"
            className="text-composite-text  col-span-1 "
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.includes("Ski In") ?? false
            }}
          />
          <Check
            context={context}
            label="Ski Out"
            inputClassName="bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
            param="skiOut"
            className="text-composite-text  col-span-1"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.includes("Ski Out") ?? false
            }}
          />
          <Check
            context={context}
            label="Ski In / Ski Out"
            inputClassName="bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
            param="skiInSkiOut"
            className="text-composite-text col-span-1 "
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.includes("Ski In / Ski Out") ?? false
            }}
          />
          <Check
            context={context}
            label="Walk To Ski Area"
            inputClassName="bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
            param="walk"
            className="text-composite-text col-span-1"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.includes("Walk To Ski Area") ?? false
            }}
          />
          <Check
            context={context}
            label="Shuttle"
            inputClassName="bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
            param="shuttle"
            className="text-composite-text col-span-1"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.includes("Shuttle Service To Ski Area") ?? false
            }}
          />
          <Check
            context={context}
            label="Drive To Ski Area"
            inputClassName="bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
            param="drive"
            className="text-composite-text col-span-1"
            resolver={(arg: any) => {
              let argl = arg as IListingSummary
              return argl.features?.includes("Drive To Ski Area") ?? false
            }}
          />
        </div>
      </div>)
  }

  /**
   * Advanced filters for global Rental entry listing search.
   */
  const rentalEntryAdvancedFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="one"
          reactSelectStyles={selectStyles}
          options={rentalCityOptions}
          className="col-span-2"
        />
        {rentalPriceFilters(context)}
        <DateRange
          context={context}
          label="Dates"
          param="dates"
          className=" py-[.4rem] border-b"
          dateInputClassNames="rounded-none bg-transparent uppercase placeholder:text-inherit"
        />
        {rentalSearchCheckBoxes(context)}
      </>
    )
  }

  /**
   * Standard filters for global Rental listing search.
   */
  const rentalStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Choose
          className="self-baseline"
          context={context}
          placeholder="Location"
          label="Location"
          param="address.city"
          resolver={arg => arg.address.city}
          match="any"
          reactSelectStyles={selectStyles}
          showAllEntriesOption={true}
        />
        {rentalPriceFilters(context)}
        <Choose
          className="self-baseline"
          context={context}
          placeholder="Features"
          label="Features"
          param="features"
          resolver={arg => arg.features}
          options={rentalFeaturesOptions}
          sort="count"
          match="all"
          reactSelectStyles={selectStyles}
        />
        <Text
          context={context}
          placeholder="MLS#, City, Zip, Area"
          param="keyword"
          label="MLS#, City, Zip, Area"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          resolver={(arg: any, text) => {
            let argl = arg as IListingSummary
            let match = text.toLowerCase()
            return (argl.mlsID && argl.mlsID.toLowerCase().includes(match)) ||
              (argl.address.text &&
                argl.address.text.toLowerCase().includes(match)) ||
              (argl.majorArea && argl.majorArea.toLowerCase().includes(match)) ||
              (argl.minorArea && argl.minorArea.toLowerCase().includes(match)) ||
              (argl.subdivision &&
                argl.subdivision.toLowerCase().includes(match)) ||
              (argl.neighborhood &&
                argl.neighborhood.toLowerCase().includes(match))
              ? true
              : false
          }}
        />
        <Check
          context={context}
          label="Our Properties"
          inputClassName=" bg-transparent before:!shadow-[inset_1rem_1rem_rgba(255,255,255,1)]"
          param="ours"
          className="text-composite-text"
          resolver={(arg: any) => {
            let argl = arg as IListingSummary
            return argl.exclusive || false
          }}
        />
      </>
    )
  }
  /**
   * Middle filter for real estate listing search
   */
  const rentalMiddleFilters = (context: ISearchContext) => (
    <>
      <Text
        context={context}
        placeholder="Keywords, City, Address"
        param="text"
        label="Keywords, City, Address"
        inputClassName="bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
        resolver={(arg: any, text) => {
          let argl = arg as IListingSummary
          let match = text.toLowerCase()
          return (argl.address.text &&
            argl.address.text.toLowerCase().includes(match)) ||
            (argl.subdivision &&
              argl.subdivision.toLowerCase().includes(match)) ||
            (argl.neighborhood &&
              argl.neighborhood.toLowerCase().includes(match))
            ? true
            : false
        }}
      />
    </>
  )
  /**
   * Sorts to be used in all Rental searches
   */
  const rentalSort = (context: ISearchContext) => (
    <Sort
      context={context}
      className="text-center py-1"
      label="Sort by: "
      sorts={[
        {
          label: "Price: $$$ to $",
          key: "priceDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (
              (l1.price || l1.priceHigh || 0) - (l0.price || l0.priceHigh || 0)
            )
          },
        },
        {
          label: "Price: $ to $$$",
          key: "priceASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (
              (l0.price || l0.priceLow || 0) - (l1.price || l1.priceLow || 0)
            )
          },
        },
        {
          label: "Bedrooms: High to Low",
          key: "bedsDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.beds || 0) - (l0.beds || 0)
          },
        },
        {
          label: "Bedrooms: Low to High",
          key: "bedsASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.beds || 0) - (l1.beds || 0)
          },
        },
        {
          label: "Sleeps: High to Low",
          key: "sleepsDESC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l1.sleeps || 0) - (l0.sleeps || 0)
          },
        },
        {
          label: "Sleeps: Low to High",
          key: "sleepsASC",
          cb: (arg0, arg1) => {
            let l0 = arg0 as IListingSummary
            let l1 = arg1 as IListingSummary
            return (l0.sleeps || 0) - (l1.sleeps || 0)
          },
        },
      ]}
    />
  )

  const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

  const peopleSort = function (context: ISearchContext) {
    return (
      <>
        <div className="w-full max-w-screen-lg flex flex-row flex-wrap lg:flex-nowrap justify-center gap-y-2 pt-16 pb-14">
          <span className="uppercase text-xs w-24 text-neutral-500 tracking-widest">
            SEARCH BY LAST NAME
          </span>
          <ul className="flex flex-row flex-wrap lg:flex-nowrap justify-center lg:justify-start gap-y-2">
            {alphabet.map((item, index) => (
              <li key={index} className={`px-2.5 border-b pb-4 ${context.currentFilterValues?.letter == item ? "border-accent border-b-8" : "border-neutral-500"}`}>
                <Link to={`${encodeURI(`#filters={"letter":"${item}"}`)}`} key={index} className="text-neutral-500">
                  {item}
                </Link>
              </li>
            ))}
            <li className={`px-2.5 border-b pb-4 ${!context.currentFilterValues?.letter ? "border-accent border-b-8" : "border-neutral-500"}`}>
              <Link to={``} className="text-neutral-500">
                ALL
              </Link>
            </li>
          </ul>
        </div>
      </>
    )
  }

  const letterFilter = function (context: ISearchContext) {
    const letterOptions = alphabet.map((item) => {
      return {
        label: item,
        value: item
      }
    })
    letterOptions.push({
      label: "All",
      value: ""
    })

    return (
      <Choose
        className="hidden"
        context={context}
        label="Letter"
        placeholder="Letter"
        customFilter={(arg, value) => {
          let argl = arg as IAgent
          let vstr = value as string
          vstr = vstr.toLowerCase()
          return argl.last &&
            argl.last.toLowerCase().startsWith(vstr) ? true : false
        }}
        param="letter"
        resolver={arg => arg.price}
        reactSelectStyles={selectStyles}
        options={letterOptions}
      />
    )
  }

  /**
   * Standard filters for global Real Estate people search.
   */
  const peopleStandardFilters = function (context: ISearchContext) {
    return (
      <>
        <Text
          context={context}
          placeholder="SEARCH BY AGENT LOCATION"
          param="location"
          label="SEARCH BY AGENT LOCATION"
          inputClassName="w-full bg-transparent text-composite-text border-composite-text placeholder:text-inherit"
          className="col-span-4"
          resolver={(arg: any, text) => {
            let argl = arg as any
            let match = text.toLowerCase()
            let regex = new RegExp(match, 'gi')
            return argl.location?.some((l: string) => l?.match(regex))
          }}
        />
        {letterFilter(context)}
      </>
    )
  }

  const htmlCols: string[] = []
  if (data.config.customFields.thirdColumnContent) {
    htmlCols.push("");
    htmlCols.push(data.config.customFields.thirdColumnContent);
  }

  /**
   * Wrap our pages in the SiteContext to provide configuration and
   * customization of various site elements.  See all usages of useSiteContext()
   * in @organic-return/foundation-gatsby-plugin-agility
   */
  return (
    <SiteContext.Provider
      value={{
        localGetModule: getModule,
        localGetPageTemplate: getPageTemplate,
        listingLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "uppercase z-50",
            layout: EListingVitalsLayout.column1Border1,
            locationIcon: false,
            priceClassName: "normal-case"
          },
          summaryProps: {
            showFeatures: false,
          },
          formProps: {
            title: "",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: {
            title: "",
            className: "w-full max-w-screen-xl mx-auto mb-10",
            markerProps: {
              icon: {
                path: 0,
                scale: 7,
                strokeColor: "white",
              }
            },
            mapTheme: EMapTheme.dark
          },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 my-24",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: false,
          contactProps: {
            contactCardProps: {
              layout: EContactCardLayout.horizontal
            }
          },
          carouselProps: {
          },
        },
        listingLayout: ListingLayoutSingleColumn,
        exclusiveLayoutProps: {
          vitalsProps: {
            composite: true,
            className: "uppercase z-50",
            layout: EListingVitalsLayout.column1Border1,
            locationIcon: false,
            priceClassName: "normal-case"
          },
          summaryProps: {
            showFeatures: false,
          },
          formProps: {
            title: "",
            classNames: "strictTwoColumn inverted property-contact",
            inputTextAreaLabel: "Add a message here...",
          },
          similarCardProps: {
            linkText: "View Listing",
            vitalsLayout: EListingVitalsLayout.row1Border0,
          },
          mapProps: {
            title: "",
            className: "w-full max-w-screen-xl mx-auto mb-10",
            markerProps: {
              icon: {
                path: 0,
                scale: 7,
                strokeColor: "white",
              }
            },
            mapTheme: EMapTheme.dark
          },
          detailsProps: {
            title: "DETAILS",
            layout: EListingDetailsLayout.groupNone,
            className: "max-w-screen-xl mx-auto px-10 my-24",
          },
          similarCarouselProps: { title: "Similar Properties" },
          rowClassName: "max-w-screen-xl mx-auto",
          showContactUsForm: true,
          contactProps: {
            contactCardProps: {
              layout: EContactCardLayout.horizontal
            }
          },
          carouselProps: {
          },
        },
        exclusiveListingLayout: ListingLayoutSingleColumn,
        rentalLayoutProps: {
          vitalsProps: {
            className: "mb-3 inverted",
          },
          detailsProps: {
            title: "Full Details",
            className: "max-w-screen-xl mx-auto",
            layout: EListingDetailsLayout.groupColumns,
          },
          similarCarouselProps: {
            title: "Similar Rentals",
          },
          summaryProps: {
            className: "max-w-screen-xl mx-auto p-4",
            showFeatures: false,
          },
          rowClassName: "max-w-screen-xl mx-auto",
          mapProps: { title: "", className: "my-10" },
          formProps: {
            classNames: "inverted strictTwoColumn property-contact",
            title: "Request Information",
            inputTextAreaLabel: "Add a message here...",
          },
          showContactUsForm: true,
        },
        listingSearchProps: {
          primaryFilters: listingStandardFilters,
          secondaryFilters: listingMiddleFilters,
          sort: listingSort,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-screen",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        listingEntrySearchProps: {
          standardFilters: listingEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        communitySearchProps: {
          primaryFilters: communityStandardFilters,
          secondaryFilters: listingMiddleFilters,
          filterClassName: "inverted",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          resultsClassName: "max-w-screen-2xl mx-auto",
          mapClassName: "w-screen",
          sort: listingSort,
        },
        rentalSearchProps: {
          sort: rentalSort,
          primaryFilters: rentalStandardFilters,
          secondaryFilters: rentalSearchCheckBoxes,
          filterClassName: "inverted text-white",
          headerClassName: "max-w-screen-xl mx-auto",
          pagerClassName: "max-w-screen-xl mx-auto",
          mapClassName: "w-screen",
          resultsClassName: "max-w-screen-2xl mx-auto",
        },
        peopleSearchProps: {
          primaryFilters: peopleStandardFilters,
          sort: peopleSort,
          headerClassName: "max-w-screen-lg mx-auto flex-wrap",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-lg mx-auto pb-24",
          filterClassName: "max-w-screen-lg mx-auto",
          showCount: false,
          pageSize: 12,
          pagerBottom: true,
        },
        globalPeopleSearchCardProps: {
          layout: EContactCardLayout.vertical,
          showOffices: true,
          officesWrapperClassNames: "!py-2",
          addressClassNames: "!w-full",

        },
        rentalEntrySearchProps: {
          standardFilters: rentalEntryFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
        },
        rentalEntryAdvancedSearchProps: {
          standardFilters: rentalEntryAdvancedFilters,
          buttonClassName: "border border-white hover:bg-button-bg-inverted",
          onSubmit: async (filters: any) => {
            let featuresFilter: string[] = []
            filters["air"] && featuresFilter.push("A/C")
            filters["waterfront"] && featuresFilter.push("Waterfront")
            filters["linens"] && featuresFilter.push("Linens Provided")
            filters["pool"] && featuresFilter.push("Pool")

            if (featuresFilter.length > 0) {
              filters = {
                ...filters,
                features: featuresFilter,
              }
            }

            const params = new URLSearchParams({
              filters: JSON.stringify(filters),
            })
            await navigate(
              data.config.customFields.rentalSearchPath +
              "#" +
              params.toString()
            )
          },
        },
        globalContactFormProps: {
        },
        globalSellFormProps: {
          layout: ESellFormLayout.row2map,
          propertyTypes: [
            {
              type: "Residential",
              subtypes: [
                "Single Family Residence",
                "Stock Cooperative",
                "Condominium",
                "Apartment",
                "Townhouse",
                "Mobile Home",
                "Mixed Use",
                "Office",
              ],
            },
            {
              type: "Rental",
              subtypes: [
                "Apartment",
                "Single Family Residence",
                "Condominium",
                "Multi Family",
                "Stock Cooperative",
                "Duplex",
                "Townhouse",
                "Mixed Use",
                "Residential",
                "Mobile Home",
                "Triplex",
                "House (detached)",
                "Office",
                "Condotel",
              ],
            },
            {
              type: "Detached Home",
              subtypes: [],
            },
            {
              type: "Multi-family",
              subtypes: [
                "Multi Family",
                "Duplex",
                "Triplex",
                "Townhouse",
              ],
            },
            {
              type: "Commercial",
              subtypes: [
                "Mixed Use",
                "Office",
                "Retail",
                "Industrial",
                "Warehouse",
                "Apartment",
                "Townhouse",
              ],
            },
            {
              type: "Lots & Land",
              subtypes: ["Unimproved Land"]
            },
            {
              type: "Condo",
              subtypes: [],
            },
            {
              type: "Business Opportunity",
              subtypes: [
                "Retail",
                "Mixed Use",
                "Industrial",
                "Office",
                "Apartment",
                "Warehouse",
              ]
            },
            {
              type: "Co-op",
              subtypes: [],
            },
            {
              type: "Multi-family Townhouse",
              subtypes: [],
            },
            {
              type: "Single-family Townhouse",
              subtypes: [],
            },
            {
              type: "Land",
              subtypes: [],
            },
            {
              type: "House (detached)",
              subtypes: [],
            },
            {
              type: "Vacant Land",
              subtypes: [],
            },
            {
              type: "Condop",
              subtypes: [],
            },
            {
              type: "Commercial Building",
              subtypes: [],
            },
            {
              type: "House (attached)",
              subtypes: [],
            },
            {
              type: "House W/accessory",
              subtypes: [],
            },
          ],
          propertyTypeSelectLabel: "Property Type",
          propertySubtypeSelectLabel: "Sub-Type",
          bedsSelect: {
            placeholder: "Beds",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          bathsSelect: {
            placeholder: "Baths",
            selectOptions: [
              {
                label: "1",
                value: "1",
              },
              {
                label: "2",
                value: "2",
              },
              {
                label: "3",
                value: "3",
              },
              {
                label: "4",
                value: "4",
              },
              {
                label: "5",
                value: "5",
              },
              {
                label: "6",
                value: "6",
              },
              {
                label: "7+",
                value: "7+",
              },
            ]
          },
          squareFootageSelect: {
            placeholder: "Sq. Ft.",
            selectOptions: [
              {
                label: "Under 1000 ft²",
                value: "Under 1000 ft²",
              },
              {
                label: "1000-2000 ft²",
                value: "1000-2000 ft²",
              },
              {
                label: "2000-3000 ft²",
                value: "2000-3000 ft²",
              },
              {
                label: "3000-4000 ft²",
                value: "3000-4000 ft²",
              },
              {
                label: "4000-5000 ft²",
                value: "4000-5000 ft²",
              },
              {
                label: "5000-6000 ft²",
                value: "5000-6000 ft²",
              },
              {
                label: "6000-7000 ft²",
                value: "6000-7000 ft²",
              },
              {
                label: "7000-8000 ft²",
                value: "7000-8000 ft²",
              },
              {
                label: "Over 8000 ft²",
                value: "Over 8000 ft²",
              },
            ]
          },
          conditionSelect: {
            placeholder: "Condition",
            selectOptions: [
              {
                label: "Excelent",
                value: "Excelent",
              },
              {
                label: "Good",
                value: "Good",
              },
              {
                label: "Average",
                value: "Average",
              },
              {
                label: "Fair",
                value: "Fair",
              },
              {
                label: "Poor",
                value: "Poor",
              },
            ],
          },
          inputTextAreaLabel: "Additional information...",
        },
        agentLayoutProps: {
          contactCardProps: {
            layout: EContactCardLayout.twoColsAndForm,
            showBio: true,
            contactUnderPhoto: true,
            showOffices: true,
            officeInfoPosition: EOfficeInfoPosition.bio,
            agentInfoClassName: "!text-body-text",
            officesWrapperClassNames: "!w-full !py-2",
          },
          showRequestInfoForm: false,
          listingsTabsProps: {
            activeListingsTabTitle: "ACTIVE",
            soldListingsTabTitle: "SOLD",
            activeTabClass: "border-b-8 border-accent text-body-text font-bold w-auto",
            defaultTabClass: "text-[#6c6c6c]",
          },
          testimonialsProps: {
            title: "TESTIMONIALS",
            headingClass: "w-full max-w-screen-lg mx-auto text-[36px] pb-12 font-light tracking-widest",
            showQuotes: false,
            columnsWrapperClass: "w-full max-w-screen-lg mx-auto flex flex-col md:flex-row items-center",
            leftColumnClass: "hidden",
            rightColumnClass: "px-0 w-full flex justify-center flex-wrap items-start",
            dividerClass: "hidden",
            showAttribution: true,
            slickSettings: {
              arrows: true,
              dots: true,
              appendDots: (dots: any) => {
                let currentIndex = dots.findIndex((dot: any) => dot.props.className == "slick-active")
                return (
                  <div>
                    <div className="flex flex-row justify-center items-center gap-2 text-sm">
                      <span className="block">{`${currentIndex + 1}`.padStart(2, "0")}</span>
                      <ul className="flex flex-row justify-center items-center">
                        {dots}
                      </ul>
                      <span className="block">{`${dots.length}`.padStart(2, "0")}</span>
                    </div>
                  </div>
                )
              },
            }
          },
          carouselListingCardVitalsLayout: EListingVitalsLayout.column1Border1
        },
        globalListingCardProps: {
          vitalsTwoLineAddress: false,
          vitalsLocationIcon: false,
          vitalsLayout: EListingVitalsLayout.column1Border1
        },
        globalListingCarouselCardProps: {
          vitalsLayout: EListingVitalsLayout.column1Border0,
          vitalsClassName: "md:py-14"
        },
        globalFeaturedAffiliatedAgentProps: {
          isImageHeadshot: true,
        },
        globalPartnerMapSearchProps: {
          satelliteMode: true,
          markerProps: {
            icon: {
              path: 0,
              scale: 7,
              strokeColor: "white",
            }
          },
          initialCenter: {
            lat: 33.7753832,
            lng: -38.2961321,
          }
        },
        partnersListingProps: {
          primaryFilters: peopleStandardFilters,
          sort: peopleSort,
          headerClassName: "max-w-screen-lg mx-auto flex-wrap",
          pagerClassName: "max-w-screen-lg mx-auto",
          resultsClassName: "max-w-screen-lg mx-auto pb-24",
          filterClassName: "w-full bg-body-bg-inverted h-28 flex flex-col justify-center",
          showCount: false,
          pageSize: 12,
          pagerBottom: true,
        },
        footerLayout: FooterLabeledColumns,
        footerProps: {
          showAttribution: true,
          offices: data.offices?.nodes,
          copyright: data.config.customFields.footerCopyright,
          colsTitles: data.config.columnsTitles,
          formProps: {
            classNames: "singleField",
            fieldsetClassName: "!p-0",
            mainLegendClassName: "hidden",
            mainListClassName: "!my-0",
            unwantedTextInputs: ["firstName", "lastName", "phoneNumber"]
          },
          htmlCols: htmlCols
        },
      }}
    >
      {children}
    </SiteContext.Provider>
  )
}
